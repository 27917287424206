
import { BlogFilter } from 'client-website-ts-library/filters';
import { Blog } from 'client-website-ts-library/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { API } from 'client-website-ts-library/services';

import Masonry from './Layout/Masonry.vue';

@Component({
  components: {
    Masonry,
  },
})
export default class Blogs extends Vue {
  @Prop({ required: true })
  private filter!: BlogFilter;

  private loading = false;

  private posts: Blog[] = [];

  private load(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.loading) resolve(false);

      this.loading = true;

      API.Blogs.Search(this.filter).then((data) => {
        this.posts = data;

        this.loading = false;
      }).catch(reject);
    });
  }

  mounted() {
    this.load();
  }

  @Watch('filter')
  handleFilterChanged() {
    this.load();
  }
}
